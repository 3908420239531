import {  Slider, InputNumber, Row, Col, Collapse } from "antd";
import {
  useBalance,
  useContractLoader,
  useContractReader,
  useGasPrice,
  useOnBlock,
  useUserProviderAndSigner,
} from "eth-hooks";
import { useExchangeEthPrice } from "eth-hooks/dapps/dex";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./App.css";
import { Account, NetworkDisplay, NetworkSwitch} from "./components";
import { NETWORKS, ALCHEMY_KEY } from "./constants";
import externalContracts from "./contracts/external_contracts";
// contracts
import deployedContracts from "./contracts/hardhat_contracts.json";
import { Transactor, Web3ModalSetup } from "./helpers";
import { useStaticJsonRPC } from "./hooks";
import BrokenSea from "./contracts/BrokenSea.json";

const { ethers } = require("ethers");

const { Panel } = Collapse;
const initialNetwork = NETWORKS.rinkeby;
const DEBUG = false;
const NETWORKCHECK = true;
const USE_BURNER_WALLET = false;
const USE_NETWORK_SELECTOR = false;
const web3Modal = Web3ModalSetup();
const providers = [
  "https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406",
  `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`,
  "https://rpc.scaffoldeth.io:48544",
];

function callback(key) {
  console.log(key);
}

function App(props) {
  const networkOptions = [initialNetwork.name, "mainnet", "rinkeby"];

  const [mintAmount, setMintAmount] = useState(1);
  const [totalMinted, setTotalMinted] = useState(0);
  const [loading, setLoading] = useState(true);
  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0]);
  const location = useLocation();

  const targetNetwork = NETWORKS[selectedNetwork];
  const blockExplorer = targetNetwork.blockExplorer;

  // load all your providers
  const localProvider = useStaticJsonRPC([
    process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : targetNetwork.rpcUrl,
  ]);
  const mainnetProvider = useStaticJsonRPC(providers);

  if (DEBUG) console.log(`Using ${selectedNetwork} network`);

  // 🛰 providers
  if (DEBUG) console.log("📡 Connecting to Mainnet Ethereum");

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  const price = useExchangeEthPrice(targetNetwork, mainnetProvider);
  const gasPrice = useGasPrice(targetNetwork, "fast");

  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider, USE_BURNER_WALLET);
  const userSigner = userProviderAndSigner.signer;

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  // The transactor wraps transactions and provides notificiations
  const tx = Transactor(userSigner, gasPrice);
  const yourLocalBalance = useBalance(mainnetContracts, address);
  const yourMainnetBalance = useBalance(mainnetProvider, address);

  const contractConfig = { deployedContracts: deployedContracts || {}, externalContracts: externalContracts || {} };
  const readContracts = useContractLoader(localProvider, contractConfig);
  const writeContracts = useContractLoader(userSigner, contractConfig, localChainId);

  const mainnetContracts = useContractLoader(mainnetProvider, contractConfig);

  // If you want to call a function on a new block
  useOnBlock(mainnetProvider, () => {
    console.log(`⛓ A new mainnet block is here: ${mainnetProvider._lastBlockNumber}`);
  });

  const purpose = useContractReader(readContracts, "YourContract", "purpose");

  //
  // 🧫 DEBUG 👨🏻‍🔬
  //
  useEffect(() => {
    if (
      DEBUG &&
      mainnetProvider &&
      address &&
      selectedChainId &&
      yourLocalBalance &&
      yourMainnetBalance &&
      readContracts &&
      writeContracts &&
      mainnetContracts
    ) {
      console.log("_____________________________________ 🏗 scaffold-eth _____________________________________");
      console.log("🌎 mainnetProvider", mainnetProvider);
      console.log("🏠 localChainId", localChainId);
      console.log("👩‍💼 selected address:", address);
      console.log("🕵🏻‍♂️ selectedChainId:", selectedChainId);
      console.log("💵 yourLocalBalance", yourLocalBalance ? ethers.utils.formatEther(yourLocalBalance) : "...");
      console.log("💵 yourMainnetBalance", yourMainnetBalance ? ethers.utils.formatEther(yourMainnetBalance) : "...");
      console.log("📝 readContracts", readContracts);
      console.log("🌍 DAI contract on mainnet:", mainnetContracts);
      console.log("🔐 writeContracts", writeContracts);
    }
  }, [
    mainnetProvider,
    address,
    selectedChainId,
    yourLocalBalance,
    yourMainnetBalance,
    readContracts,
    writeContracts,
    mainnetContracts,
    localChainId,
  ]);

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
    // eslint-disable-next-line
  }, [setInjectedProvider]);
  const CONTRACT_ADDRESS = "0xb46Be0FA02Dc6e79b5dBadDDde2F2d0a64da73c4";
  const ethValue = (mintAmount * 0.0169).toFixed(4);

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 100) {
      newMintAmount = 100;
    }
    setMintAmount(newMintAmount);
  };

  const HandleMint = async event => {
    try {
      const contract = new ethers.Contract(CONTRACT_ADDRESS, BrokenSea.abi, injectedProvider);
      const signer = injectedProvider.getSigner(0);
      const contractSigner = contract.connect(signer);

      const ethValueString = ethValue.toString();
      console.log(ethValue.toString());

      const txParamsOverride = {
        value: ethers.utils.parseEther(ethValueString),
      };

      const tx = await contractSigner.crashShip(mintAmount, txParamsOverride);
      await tx.wait();
    } catch (error) {
      alert(error.error.message);
      console.error(error);
    }
  };

  async function getTotalSupply() {
    try {
      const contract = new ethers.Contract(CONTRACT_ADDRESS, BrokenSea.abi, injectedProvider);
      const data = await contract.totalSupply();

      setTotalMinted(data.toNumber());
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(function () {
    async function fetchTotals() {
      if (!ethers) {
        console.log("Install MetaMask");
        setLoading(false);
        return;
      }

      await getTotalSupply();
      setLoading(false);
    }
    fetchTotals();
  });

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  return (
    <div className="App">
      <NetworkDisplay
        NETWORKCHECK={NETWORKCHECK}
        localChainId={localChainId}
        selectedChainId={selectedChainId}
        targetNetwork={targetNetwork}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
        USE_NETWORK_SELECTOR={USE_NETWORK_SELECTOR}
      />
      <header>
        <a href="/">
          <h1 className="HeaderTitle">Broken Sea</h1>
        </a>
        <div>
          {USE_NETWORK_SELECTOR && (
            <div>
              <NetworkSwitch
                networkOptions={networkOptions}
                selectedNetwork={selectedNetwork}
                setSelectedNetwork={setSelectedNetwork}
              />
            </div>
          )}
          <Account
            address={address}
            localProvider={localProvider}
            userSigner={userSigner}
            mainnetProvider={mainnetProvider}
            price={price}
            web3Modal={web3Modal}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            blockExplorer={blockExplorer}
          />
        </div>
      </header>
      <div className="container" id="Mint">
        <div className="mintPage">
          <div className="mintTop">
            <h1 className="mintTitle">Welcome To Broken Sea</h1>
            <p className="mintdescription">Find your wrecked Ship!</p>
            <p className="mintdescription">Cost to Mint: Ξ{ethValue}</p>
          </div>

          {/* <img
          className="mintImage"
          src={MintLogo}
          alt="Robos"
          layout="intrinsic"
          width="175"
          height="175"
        /> */}
          <div className="countContainer">
            <p className="mintAmount">{mintAmount}</p>
            <Slider 
              defaultValue={1} 
              disabled={false} 
              min={1} 
              max={100} 
              onChange={setMintAmount} 
              tooltipVisible={false} 
              trackStyle={{ backgroundColor: '#00f7e3', height: 10 }}
              handleStyle={{
                height: 20,
                width: 20,
                backgroundColor: 'black',
              }}
              railStyle={{ backgroundColor: 'black', height: 10}}
            />
          </div>
          <button onClick={HandleMint} className="mintButton">
            Find Wreck
          </button>

          <h2 className="mintSupply">Wrecks left to find: {totalMinted} / 404</h2>
          <Collapse ghost={true} defaultActiveKey={["0"]} onChange={callback}>
            <Panel className="faqText" header="What are THOOOOOSSSEEEEE?" key="1">
              <p className="faqText">Absolute Wrecks</p>
            </Panel>
            <Panel className="faqText" header="Where is the Road Map?" key="2">
              <p className="faqText">It Sunk</p>
            </Panel>
            <Panel className="faqText" header="Click to find out!" key="3">
              <p className="faqText">We Like The Wreck</p>
            </Panel>
            <Panel className="faqText" header="EtherScan Contract" key="4">
              <p><a className="faqText" href='https://rinkeby.etherscan.io/token/0xb46be0fa02dc6e79b5dbadddde2f2d0a64da73c4'>Broken Sea Contract</a></p>
            </Panel>
            <Panel className="faqText" header="Collection" key="5">
              <p className="faqText"><a className="faqText" href='#'>Opensea</a><br/><a href='#'>Looks Rare</a></p>
            </Panel>
          </Collapse>
        </div>
      </div>

    </div>
  );
}

export default App;
